import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Layout from "../../components/layout/Navbar";
import "./Generator.css";
import { Link } from "react-router-dom";
import axios from "axios";
import HighriseGenerator from "./highriseGenerator";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      Main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "10rem 0 0 0 ",
        fontSize: "2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          // backgroundColor: "red",
          fontSize: "1.5rem",
        },
      },
      cardmain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "300px",
        padding: "20px",
        color: "#fff",
        background: "red",
        flexDirection: "column",
        borderRadius: "1rem",
      },
      cardHeading: {
        fontSize: "1.5rem",
      },
      cardNumber: {},
      [theme.breakpoints.down("md")]: {
        // backgroundColor: "green",
        flexDirection: "column",
      },
      MainCardDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "1200px",
      },
      MainDiv: {
        display: "flex",
        maxWidth: "1200px",
        flexDirection: "column",
      },
      MainHeading: {
        fontSize: "24px",
        fontWeight: "700",
      },
    }),
  { withTheme: true }
);

function Highrise() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [jobDataBalance, setJobDataBalance] = useState([]);
  const [freeData, setFreeData] = useState([]);
  const [Loading, setLoading] = useState(false);

  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [balanceCurrentItems, setBalanceCurrentItems] = useState(null);
  const [FreeCurrentItems, setFreeCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [disable, setDisable] = useState(false);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    setLoading(false);
    setDisable(true);
    if (AdminId) {
      setTimeout(() => {
        axios
          .get(`https://api.s4.apancollections.com/jobsheet/${AdminId}/highrises`)
          .then((res) => {
            setDisable(false);
            setData(res.data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      }, 1000);
    }
  }, []);
  useEffect(() => {
    setLoading(false);
    setDisable(true);
    if (AdminId) {
      setTimeout(() => {
        axios
          .get(
            `https://api.s4.apancollections.com/jobsheetbalance/${AdminId}/highrises`
          )
          .then((res) => {
            setDisable(false);
            setJobDataBalance(res.data);
            setLoading(true);
          })
          .catch((error) => {
            setLoading(true);
          });
      }, 1000);
    }
  }, []);
  useEffect(() => {
    setLoading(false);
    setDisable(true);
    if (AdminId) {
      setTimeout(() => {
        axios
          .get(
            `https://api.s4.apancollections.com/jobsheetfree/${AdminId}/highrises`
          )
          .then((res) => {
            setDisable(false);
            setFreeData(res.data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, data]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setBalanceCurrentItems(jobDataBalance.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, jobDataBalance]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setFreeCurrentItems(freeData.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, freeData]);

  const handleClcikOpen = () => {
    setOpen(true);
  };

  const addExpenceHandler = (expence) => {
    // console.log(expence, "props");
  };

  return (
    <Layout>
      {open ? (
        <HighriseGenerator onAddExpenceHandler={addExpenceHandler} />
      ) : (
        <>
          <div>
            <div className="Heading">Highrise</div>
          </div>
          <br />
          <br />
          <div className={classes.MainDiv}>
            <div className={classes.tableMainDiv}>
              <div className={classes.tableDiv}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <tbody style={{ display: "flex", flexDirection: "row" }}>
                    {currentItems &&
                      currentItems.length > 0 &&
                      currentItems.map((data, i) => (
                        <tr key={i}>
                          <th>
                            <Typography variant="body">
                              {data.columnName == "balanceassigned"
                                ? "Balance Assigned"
                                : "" || data.columnName == "balance"
                                ? "Balance"
                                : "" || data.columnName == "currentBalance"
                                ? "Current Balance"
                                : "" || data.columnName == "arrears"
                                ? "Arrears"
                                : "" || data.columnName == "payments"
                                ? "Payments"
                                : ""}
                            </Typography>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                  <tbody style={{ display: "flex", flexDirection: "row" }}>
                    {balanceCurrentItems &&
                      balanceCurrentItems.length > 0 &&
                      balanceCurrentItems.map((jobDataBalance, j) => (
                        <tr key={j}>
                          <th>
                            <Typography variant="body">
                              {jobDataBalance.columnName == "excelId"
                                ? "Id"
                                : "" || jobDataBalance.columnName == "State"
                                ? "State"
                                : "" || jobDataBalance.columnName == "SAN"
                                ? "SAN"
                                : "" || jobDataBalance.columnName == "Owner1"
                                ? "Owner 01"
                                : "" ||
                                  jobDataBalance.columnName == "PropertyStatus"
                                ? "Property Status"
                                : "" || jobDataBalance.columnName == "PropAddr1"
                                ? "Property Address 01"
                                : "" || jobDataBalance.columnName == "PropAddr2"
                                ? "Property Address 02"
                                : "" || jobDataBalance.columnName == "PropAddr3"
                                ? "Property Address 03"
                                : "" || jobDataBalance.columnName == "PropAddr4"
                                ? "Property Address 04"
                                : "" || jobDataBalance.columnName == "PropAddr5"
                                ? "Property Address 05"
                                : "" || jobDataBalance.columnName == "Suburb"
                                ? "Suburb"
                                : "" || jobDataBalance.columnName == "Arrears"
                                ? "Arrears"
                                : "" ||
                                  jobDataBalance.columnName == "CurrentBalance"
                                ? "Current Balance"
                                : ""}
                            </Typography>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                  <tbody style={{ display: "flex", flexDirection: "row" }}>
                    {FreeCurrentItems &&
                      FreeCurrentItems.length > 0 &&
                      FreeCurrentItems.map((freeData, k) => (
                        <tr key={k}>
                          <th>
                            <Typography variant="body">
                              {freeData.columnName}
                            </Typography>
                          </th>
                        </tr>
                      ))}
                  </tbody>
                  <tbody>
                    {!Loading && disable && (
                      <tr
                        style={{
                          borderCollapse: "collapse",
                          padding: "0px !important",
                        }}
                      >
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          colSpan="12"
                        >
                          <Typography sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Typography>
                        </th>
                      </tr>
                    )}
                  </tbody>
                  <tbody>
                    {data.length === 0 &&
                      jobDataBalance.length === 0 &&
                      freeData.length === 0 &&
                      Loading && (
                        <tr style={{ borderCollapse: "collapse" }}>
                          <th
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            colSpan="12"
                          >
                            <Typography variant="body">No data</Typography>
                          </th>
                        </tr>
                      )}
                  </tbody>
                </table>
                <div className="job-container">
                  <Link to="/highriseGenerator">
                    <button
                      className="sheetButton mt-4"
                      onClick={handleClcikOpen}
                    >
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
export default Highrise;

import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./AddPermission.css";
import CloseIcon from "@mui/icons-material/Close";
import { useToasts } from "react-toast-notifications";

function AddPermission({ closeModel, edit, roleId }) {
  const [checkboxValues, setCheckboxValues] = useState("");
  const { addToast } = useToasts();
  const [permissionValues, setPermissionValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function handleCheckboxChange(event) {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckboxValues([...checkboxValues, value]);
    } else {
      setCheckboxValues(checkboxValues.filter((v) => v !== value));
    }
  }

  useEffect(() => {
    axios
      .get(`employeePermission/${roleId.id}`)
      .then((res) => {
        setPermissionValues(res.data);
        const checkedValues = res.data.map((item) => {
          return item.permissionType;
        });
        setCheckboxValues(checkedValues);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  }, []);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    setLoading(false);
    if (AdminId) {
      axios
        .get(`https://api.s4.apancollections.com/permission/${AdminId}`)
        .then((res) => {
          setData(res.data);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
        });
    }
  }, []);

  const permissionData = data.map((item) => {
    return item.permissionType;
  });

  const handleApi = () => {
    const formtedValues = checkboxValues
      .map((item) => {
        return item;
      })
      .join(",");
    const data = {
      roleId: roleId.id,
      adminPermissions: formtedValues,
    };
    axios
      .post("employeePermission", data)
      .then((result) => {
        addToast("Successfully Added", {
          appearance: "success",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setTimeout(() => {
          closeModel(false);
          window.location.reload(false);
        }, 1000);
      })
      .catch((error) => {
        addToast("username or password is incorrcet", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
      });
  };

  return (
    <div className="card-background">
      <div className="Addrider-card">
        <div className="card-head" style={{ display: "block" }}>
          <div className="icon-div" onClick={() => closeModel(false)}>
            <CloseIcon style={{ color: "#501ABF" }} />
          </div>
          <h1>Permission</h1>
        </div>
        <div className="conatiner_04 mt-4">
          <span>
            <div className="mb-3 conatiner_03">
              {permissionData.includes("productivity") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="productivity"
                    checked={checkboxValues.includes("productivity")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Productivity</label>
                </div>
              )}
              {permissionData.includes("payment") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="payment"
                    checked={checkboxValues.includes("payment")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Payment</label>
                </div>
              )}
              {permissionData.includes("reportManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="reportManager"
                    checked={checkboxValues.includes("reportManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Report Manager</label>
                </div>
              )}
              {permissionData.includes("importManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="importManager"
                    checked={checkboxValues.includes("importManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Import Manager</label>
                </div>
              )}
              {permissionData.includes("exportManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="exportManager"
                    checked={checkboxValues.includes("exportManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Export Manager</label>
                </div>
              )}
              {permissionData.includes("visualManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="visualManager"
                    checked={checkboxValues.includes("visualManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Visual Manager</label>
                </div>
              )}
              {permissionData.includes("humanResources") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="humanResources"
                    checked={checkboxValues.includes("humanResources")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Human Resources</label>
                </div>
              )}
            </div>
          </span>
          <span>
            <div className="mb-3 conatiner_03">
              {permissionData.includes("jobsheet") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="jobsheet"
                    checked={checkboxValues.includes("jobsheet")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">
                    Job Sheet + Job Sheet Generator
                  </label>
                </div>
              )}
              {permissionData.includes("dataManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="dataManager"
                    checked={checkboxValues.includes("dataManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Data Manager</label>
                </div>
              )}
              {permissionData.includes("callManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="callManager"
                    checked={checkboxValues.includes("callManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Call Manager</label>
                </div>
              )}
              {permissionData.includes("permissionManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="permissionManager"
                    checked={checkboxValues.includes("permissionManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">
                    Permisssion Manager
                  </label>
                </div>
              )}
              {permissionData.includes("riderManager") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="riderManager"
                    checked={checkboxValues.includes("riderManager")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Rider Manager</label>
                </div>
              )}
              {permissionData.includes("setup") && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="setup"
                    checked={checkboxValues.includes("setup")}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Setup</label>
                </div>
              )}
            </div>
          </span>
        </div>
        <button className="login-submit" onClick={handleApi}>
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default AddPermission;
